import React from "react";
import { useInView } from 'react-intersection-observer';
import TESTIMONIALS from "../data/testimonials";
import quoteSign from "../images/quotes_sign.png";

const Testimonials = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
    threshold: 0.1, // Percentage of the element that is in view before the callback triggers
  });

  return (
    <div className="bg-neutral-900" id="#testimonials">
      <div className="container mx-auto">
        <div className="flex flex-col lg:py-28 py-10">
          <h2 className="text-white lg:text-display-lg text-display-md font-semibold lg:pl-10">
            What my customers say
          </h2>
          <div className={`flex flex-col lg:flex-row grid-cols-1 md:pt-24 pt-16 lg:pb-48 md:pb-40 pb-20 justify-between items-center text-center ${inView ? 'animate-slideInUp' : 'opacity-0'}`}
            ref={ref} >
            {TESTIMONIALS.map((testimonial, index) => (
              <div className="rounded cursor-pointer shadow-lg bg-primary-100 p-8 mt-14 lg:mt-9 mx-2 relative transform transition duration-150 hover:scale-105" onClick={() => {
                if (testimonial.commentUrl) {
                  window.open(testimonial.commentUrl, '_blank');
                }
              }}>
                <div className="absolute top-0 -mt-10 left-1/2 transform -translate-x-1/2 bg-primary-100 p-3 rounded-full">
                  <img
                    src={testimonial.imageUrl}
                    className="h-16 w-16 rounded-full object-cover"
                    alt={testimonial.name}
                  />
                </div>
                {testimonial.translated &&
                  <div className="absolute  top-0 right-0 mr-2 bg-neutral-400 p-2 rounded-b-md">
                    <p className="text-xxs text-white">Translated</p>
                  </div>
                }
                <div className="flex-row pt-10 pb-4">
                  <blockquote className="mb-4 text-black relative">
                    <p className="text-sm text-black italic text-center  relative">
                      <img className='w-6 h-6 -mt-5 mr-2 transform rotate-180 inline-block align-middle' src={quoteSign} alt="quote sign" />
                      {testimonial.comment}
                      <img className='w-6 h-6 -mb-4 ml-1  inline-block align-middle' src={quoteSign} alt="quote sign" />
                    </p>
                  </blockquote>
                  <div className="text-center mt-4">
                    <p className="text-blue-500 font-bold">{testimonial.name}</p>
                    <p className="text-xs text-black">{testimonial.position}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
