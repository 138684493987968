import React from "react";
import Button from "./button";
import { useInView } from 'react-intersection-observer';
import image from "../images/test.gif";

const Hero = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
    threshold: 0.1, // Percentage of the element that is in view before the callback triggers
  });

  return (
    <div className="bg-neutral-900">
      <div className="container mx-auto">
        <div className={`flex flex-col lg:flex-row grid-cols-1 md:pt-32 pt-16 lg:pb-48 md:pb-40 pb-20 justify-between items-center text-center ${inView ? 'animate-slideInUp' : 'opacity-0'}`}
            ref={ref} >
          <div className="lg:col-span-1"></div>
          <img src={image} alt="hero image" className="w-7/12 lg:w-5/12" />
          <div className="lg:col-span-10 flex flex-col items-center">
            <h1 className="lg:text-display-2xl md:text-display-xl text-display-md text-neutral-50 font-semibold pb-10">
              Building captivating website for your business
            </h1>
            <div className="flex row gap-1">
              <Button label="CONTACT ME" link="#footer" size="lg" />
            </div>
          </div>

          <div className="lg:col-span-1"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
