import React from "react";

import UI from "../images/service-icons/palette-de-peinture.gif";
import SEO from "../images/service-icons/chercher.gif";
import RWD from "../images/service-icons/ordinateur.gif";
import Button from "./button";
import ServiceItem from "./serviceItem";

const Services = () => {


  return (
    <div id="#services">
      <div className="md:container md:mx-auto">
        <div className="lg:py-30 md:py-16 pt-5 pb-12">
          <div className="flex flex-col relative bg-primary-100 lg:py-0 py-10">
            <div className="absolute lg:block hidden h-16 bg-white bottom-0 left-0 right-0"></div>
            <div className="absolute lg:block hidden h-16 bg-white top-0 left-0 right-0"></div>
            <div className="grid lg:grid-cols-12 grid-cols-1 xl:gap-8 items-center  relative">
              <div className="lg:col-span-5 flex flex-col items-start xl:px-18 lg:px-10 md:px-8 px-5 lg:py-32 md:pb-4 pb-10">
                <h2 className="lg:text-display-lg text-display-md font-semibold pb-4">
                  What I do for my customers
                </h2>
                <p className="text-body-md font-normal text-neutral-600 pb-8">
                  Empowering your online presence with bespoke solutions tailored to your needs
                </p>
                <Button label="CONNECT WITH ME" link="#footer" size="lg" />
              </div>
              <div className="lg:col-span-7 flex md:flex-row flex-col lg:gap-8 gap-6 items-center xl:pr-16 lg:pr-10 lg:pl-0 md:px-8 px-5 relative">
                <div className="flex flex-col basis-1/2 lg:gap-8 gap-6 relative">
                  <ServiceItem
                    img={UI}
                    title="UI/UX Design"
                    description="Crafting compelling and intuitive designs that captivate your customers"
                    animationType="slideInUp"
                  />
                </div>
                <div className="flex flex-col basis-1/2 lg:gap-3 gap-6 relative">
                  <ServiceItem
                    img={SEO}
                    title="SEO Optimization"
                    description="Boosting your online presence with strategic SEO practices"
                    animationType="slideInRight"
                  />
                  <ServiceItem
                    img={RWD}
                    title="Responsive Web Development"
                    description="Delivering seamless experiences across all devices, reaching your customers wherever they are"
                    animationType="slideInRight" 
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
