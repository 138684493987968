import visiperfLogo from '../images/customers/visiperf_logo.png';
import theSpiderLogo from '../images/customers/thespider_logo.png';

const TESTIMONIALS = [
    {
      name: 'Romain Jeannoutot',
      translated: true,
      position: 'Lead Dev at Visiperf',
      commentUrl: 'https://www.malt.fr/profile/elwalidkadura',
      comment: 'Walid has consistently demonstrated a very good mastery of technical skills in web development, as well as a remarkable ability to solve problems creatively. Always motivated, Walid has contributed to several key projects, performing quality work. Thanks again Walid.',
      original: 'Walid a constamment démontré une très bonne maîtrise des compétences techniques en développement web, ainsi qu\'une capacité remarquable à résoudre les problèmes de manière créative. Toujours motivé, Walid a contribué à plusieurs projets clés, effectuant un travail de qualité. Encore merci à toi.',
      imageUrl: visiperfLogo,
    },
    {
      name: 'Julien Litrico',
      position: 'Project Manager at Visiperf',
      translated: true,
      commentUrl: 'https://www.malt.fr/profile/elwalidkadura',
      comment: 'Walid is a rare asset within any team. His versatility as a backend and frontend developer, his ease of communication, and his constant positivity are catalysts that have greatly contributed to strengthening our team on a daily basis.',
      original: 'Walid est un atout rare au sein de toute équipe. Sa polyvalence en tant que développeur backend et frontend, sa facilité de communication et sa positivité constante sont des catalyseurs qui ont grandement contribué à renforcer notre équipe au quotidien.',
      imageUrl: visiperfLogo,
    },
    {
      name: 'Sol Solomon',
      translated: false,
      position: 'Founder / CEO at The Spider',
      commentUrl: 'https://www.malt.fr/profile/elwalidkadura',
      comment: 'El Walid is a joy to work with. He brings optimism to work. Walid has a tremendous comprehension of web development and computer science. He loves solving problems with code and clean modern design. I highly recommend working with El Walid.',
      original: 'El Walid is a joy to work with. He brings optimism to work. Walid has a tremendous comprehension of web development and computer science. He loves solving problems with code and clean modern design. I highly recommend working with El Walid.',
      imageUrl: theSpiderLogo,
    },
  ];
  
export default TESTIMONIALS;