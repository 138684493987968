import React from "react";
import PropTypes from "prop-types";
import { useInView } from 'react-intersection-observer';

const ServiceItem = ({ img, title, description, animationType }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
    threshold: 0.1, // Percentage of the element that is in view before the callback triggers
  });

  return (
    <div className={`bg-white p-8 shadow-xl
    ${inView ? 'animate-'+animationType:  'opacity-0' }
    `}
      ref={ref}
    >
      <img src={img} alt={title} className="pb-8 lg:w-4/12 w-2/12" />
      <p className="text-display-xs font-semibold pb-4">
        {title}
      </p>
      <p className="text-body-md font-normal text-neutral-600">
        {description}
      </p>
    </div>

  );
};

ServiceItem.propTypes = {
  img: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  animationType: PropTypes.string.isRequired,
};
export default ServiceItem;
